import {
  AnchorLinkUndecorated,
  BulletList,
  BulletListItem,
  Column,
  ColumnWidth,
  Heading,
  Row,
  Segment,
  Text,
} from '@blueorigin/blue-branding-kit';
import {
  ValidationCheckbox,
  ValidationForm,
  ValidationSubmitButton,
} from '@blueorigin/form-components';
import { required } from '@blueorigin/input-validation';
import * as _ from 'lodash';
import React = require('react');
import { Link } from 'react-router-dom';

// tslint:disable:no-var-requires
const NewShepard = require('../../assets/BlueOrigin_NewShepard_Launch.jpg');

const TERMS_LINK = 'http://www.blueorigin.com/terms-of-use';
const PRIVACY_LINK = 'http://www.blueorigin.com/privacy-policy';
const ASTRONAUT_INFO_LINK = 'http://www.blueorigin.com/new-shepard/become-an-astronaut/';

export interface InvitationAcceptProps {
  history?: any;
  match?: any;
  buyer: string;
}

export class InvitationAccept extends React.Component<InvitationAcceptProps> {
  public formConfig = {
    agree: required,
  };

  public render() {
    const { buyer, match } = this.props;
    return (
      <ValidationForm config={this.formConfig} onSubmit={this.onSubmit}>
        <Segment>
          <Heading>Invitation to Space</Heading>
        </Segment>
        <Segment>
          <Row>
            <Column width={ColumnWidth.fillWidth}>
              <Segment>
                <Text>
                  <strong>{buyer}</strong> has placed a deposit with Blue Origin for you to fly to
                  space on New Shepard!{' '}
                  <AnchorLinkUndecorated target="_blank" rel="noopener" href={ASTRONAUT_INFO_LINK}>
                    Learn more
                  </AnchorLinkUndecorated>
                </Text>
              </Segment>
              <Segment>
                <BulletList>
                  <BulletListItem>
                    {buyer} is solely responsible for the full price of your flight with Blue
                    Origin, though travel, lodging, and dining are not fully covered.
                  </BulletListItem>
                  <BulletListItem>
                    All astronauts are required to complete a non-disclosure agreement and consent
                    cross-waiver form prior to flight.
                  </BulletListItem>
                  <BulletListItem>
                    Blue Origin requires periodic height and weight measurements to help us prepare
                    a safe flight manifest.
                  </BulletListItem>
                </BulletList>
              </Segment>
            </Column>
            <Column width="0 0 auto">
              <img src={NewShepard} width="200" />
            </Column>
          </Row>
        </Segment>
        <Segment>
          <ValidationCheckbox field="agree">
            I have read and agree to the Blue Origin{' '}
            <AnchorLinkUndecorated target="_blank" rel="noopener" href={TERMS_LINK}>
              Terms of Service
            </AnchorLinkUndecorated>{' '}
            and{' '}
            <AnchorLinkUndecorated target="_blank" rel="noopener" href={PRIVACY_LINK}>
              Privacy Policy
            </AnchorLinkUndecorated>
          </ValidationCheckbox>
        </Segment>
        <Segment>
          <ValidationSubmitButton size="large">Accept invitation</ValidationSubmitButton>
        </Segment>
        <Segment>
          <Link to={`${match.url}/decline`}>Prefer to decline?</Link>
        </Segment>
      </ValidationForm>
    );
  }

  public onSubmit = () => {
    const { match } = this.props;
    this.props.history.push(`${match.url}/create-account`);
    return true;
  };
}
