import { Auth, Error, ErrorDialog, Heading, Segment, Text } from '@blueorigin/blue-branding-kit';
import { AnalyticsContext } from '@blueorigin/customer-analytics/components/analytics-provider';
import { UserInfo } from '@blueorigin/input-validation';
import { BlueHeader } from '@blueorigin/public-site-components/components/blue-header/blue-header';
import { withOktaAuth } from '@okta/okta-react';
import { IOktaContext } from '@okta/okta-react/bundles/types/OktaContext';
import * as React from 'react';
import { connect } from 'react-redux';
import { PasswordForm } from '../components/password-form';
import { AppState } from '../redux/store';
import {
  ExpiredPasswordChangeFailReason,
  ExpiredPasswordChangeResult,
  PasswordService,
} from '../service/password-service';

export interface ChangePasswordProps extends IOktaContext {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  history?: any;
}

export interface ChangePasswordState {
  error: ExpiredPasswordChangeFailReason;
  errorSummary: string;
  newPassword: string;
  userInfo: UserInfo;
}

export class ExpiredPasswordComponent extends React.Component<
  ChangePasswordProps,
  ChangePasswordState
> {
  public static contextType = AnalyticsContext;

  constructor(props: ChangePasswordProps) {
    super(props);
    this.state = {
      error: null,
      errorSummary: null,
      newPassword: '',
      userInfo: {
        userName: props.email,
        firstName: props.firstName,
        lastName: props.lastName,
      },
    };
  }

  public componentDidMount() {
    // If required data for this route isn't in store, kick back to login page
    if (!this.props.email || !this.props.password) {
      this.context.analyticsService.event(
        'Auth',
        'View password expired page with incorrect context',
      );
      this.props.history.push('/');
      return;
    }

    this.context.analyticsService.event('Auth', 'View password expired');
  }

  public render() {
    const { errorSummary, error } = this.state;
    return (
      <BlueHeader>
        <Auth>
          <div style={{ color: 'black' }}>
            <Segment>
              <Heading>Your password has expired</Heading>
            </Segment>
            <Segment>
              <Text>Enter a new password to continue</Text>
            </Segment>
            <PasswordForm
              buttonText="Save and continue"
              onSubmit={this.onSubmit}
              userInfo={this.state.userInfo}
              error={error}
              errorSummary={errorSummary}
              onPasswordFormChange={this.onPasswordFormChange}
            />
            <Segment>{error && this.renderError()}</Segment>
          </div>
        </Auth>
      </BlueHeader>
    );
  }

  public onSubmit = (newPassword: string) => {
    this.context.analyticsService.event('Auth', 'Changing expired password');
    this.setState({
      ...this.state,
      newPassword,
    });

    this.executeUpdate(newPassword);
  };

  public tryAgain = () => {
    this.context.analyticsService.event('Auth', 'Changing expired password try again');
    this.executeUpdate(this.state.newPassword);
    this.onPasswordFormChange();
  };

  public onPasswordFormChange = () => {
    this.setState({
      ...this.state,
      error: null,
      errorSummary: null,
    });
  };
  public executeUpdate = (newPassword: string) => {
    const { email, password } = this.props;
    PasswordService.changeExpiredPassword(this.props.oktaAuth, email, password, newPassword)
      .then(result => this.onPasswordChanged(result))
      .catch(error => this.onPasswordChangedFailed(error.type, error.errorSummary));
  };

  public onPasswordChanged = (result: ExpiredPasswordChangeResult) => {
    this.context.analyticsService.event('Auth', 'Changing expired password success');
    this.props.oktaAuth.signInWithRedirect({ sessionToken: result.token });
  };

  public onPasswordChangedFailed = (
    error: ExpiredPasswordChangeFailReason,
    errorSummary: string = null,
  ) => {
    this.context.analyticsService.event('Auth', 'Changing expired password failure');
    this.setState({
      ...this.state,
      error,
      errorSummary,
    });
  };

  public renderError = () => {
    const { error, errorSummary } = this.state;
    if (errorSummary) {
      return <Error data={errorSummary}>{errorSummary}</Error>;
    }
    return (
      <ErrorDialog data={error} tryAgain={this.tryAgain}>
        Password could not be changed. Please check internet connection.
      </ErrorDialog>
    );
  };
}

export const mapStateToProps = (state: AppState, props: ChangePasswordProps) => {
  return {
    ...props,
    email: state.credentials.email,
    password: state.credentials.password,
    firstName: state.credentials.firstName,
    lastName: state.credentials.lastName,
  };
};

export const ExpiredPassword = withOktaAuth(
  connect(mapStateToProps, null)(ExpiredPasswordComponent),
);
