import { AppLoading, Auth, Heading, Segment, Text } from '@blueorigin/blue-branding-kit';
import { AnalyticsContext } from '@blueorigin/customer-analytics/components/analytics-provider';
import { Button } from '@material-ui/core';
import { withOktaAuth } from '@okta/okta-react';
import { IOktaContext } from '@okta/okta-react/bundles/types/OktaContext';
import * as _ from 'lodash';
import * as React from 'react';
import { StyledLogoWrap } from '../auth/login';
import { appConfig } from '../config';
import { ASTRONAUT_GROUP, KYC_GROUP, PAYLOAD_GROUP, PEDIGREE_GROUP } from '../constants';

// tslint:disable:no-var-requires
const Earth = require('../assets/public.png');
const Logo = require('../assets/logo-blue-origin.svg');

enum ConnectDisplayMode {
  Fetching,
  Select,
  Redirect,
  Unassigned,
}

const ConnectGroups = [PAYLOAD_GROUP, ASTRONAUT_GROUP, PEDIGREE_GROUP];

const ConnectProperties: { [key: string]: { name: string; url: string } } = {
  [PAYLOAD_GROUP]: {
    name: 'Payloads',
    url: appConfig.payloadUri,
  },
  [ASTRONAUT_GROUP]: {
    name: 'Astronauts',
    url: appConfig.astronautUri,
  },
  [PEDIGREE_GROUP]: {
    name: 'Pedigree',
    url: appConfig.pedigreeUri,
  },
};

export type ConnectProps = IOktaContext;

export interface ConnectState {
  matchGroups: any;
  displayMode: ConnectDisplayMode;
  redirectTo: string;
}

export class ConnectComponent extends React.Component<ConnectProps, ConnectState> {
  public static contextType = AnalyticsContext;

  public constructor(props: ConnectProps) {
    super(props);
    this.state = {
      matchGroups: null,
      displayMode: ConnectDisplayMode.Fetching,
      redirectTo: null,
    };
  }

  public componentDidMount() {
    this.props.oktaAuth.getUser().then(user => {
      this.processGroups(_.get(user, ['groups'], []));
    });
  }

  public onRedirectTo = (redirectTo: string) => {
    this.setState({ displayMode: ConnectDisplayMode.Redirect, redirectTo });
    this.context.analyticsService.event('Auth', `Redirecting to: ${redirectTo}`);
    window.location.assign(redirectTo);
  };

  public render() {
    const { displayMode } = this.state;

    if (_.includes([ConnectDisplayMode.Fetching, ConnectDisplayMode.Redirect], displayMode)) {
      return <AppLoading />;
    }

    if (displayMode === ConnectDisplayMode.Select) {
      const { matchGroups } = this.state;

      return (
        <Auth image={Earth} marginTop="150px">
          <div style={{ color: 'black' }}>
            <Segment>
              <StyledLogoWrap src={Logo} alt="Logo" width="175" />
            </Segment>
            <Segment>
              <Heading>Login Successful</Heading>
            </Segment>
            <Segment>
              <Text>Select an app to continue</Text>
            </Segment>
            {_.map(matchGroups, g => (
              <Segment key={ConnectProperties[g].name}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => this.onRedirectTo(ConnectProperties[g].url)}
                  fullWidth
                >
                  {ConnectProperties[g].name}
                </Button>
              </Segment>
            ))}
            <Segment>
              <Button color="primary" onClick={() => this.onRedirectTo('/signout')} fullWidth>
                Sign out
              </Button>
            </Segment>
          </div>
        </Auth>
      );
    }

    return (
      <Auth image={Earth} marginTop="150px">
        <div style={{ color: 'black' }}>
          <Segment>
            <StyledLogoWrap src={Logo} alt="Logo" width="175" />
          </Segment>
          <Segment>
            <Heading>Login Successful</Heading>
          </Segment>
          <Segment>
            <Text>Your account is not assigned to any apps.</Text>
          </Segment>
        </div>
      </Auth>
    );
  }

  private processGroups(groups: any) {
    const matchGroups = _.filter(ConnectGroups, g => _.includes(groups, g));

    if (matchGroups.length > 1) {
      this.setState({ displayMode: ConnectDisplayMode.Select, matchGroups });
      this.context.analyticsService.event('Auth', 'Select app page');
    } else if (matchGroups.length === 1) {
      this.onRedirectTo(ConnectProperties[matchGroups[0]].url);
    } else {
      this.setState({ displayMode: ConnectDisplayMode.Unassigned });
      this.context.analyticsService.event('Auth', 'User has no assigned apps');
    }
  }
}

export const Connect = withOktaAuth(ConnectComponent);
