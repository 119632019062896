import { ClickTracker } from '@blueorigin/customer-analytics/components/click-tracker';
import * as React from 'react';

export const RegistrationConsent = (props: any) => (
  <React.Fragment>
    Due to the European Union data privacy Policy &mdash; General Data Protection Regulation (GDPR)
    &mdash; please click to confirm your consent for us to store your personal data. Please note
    that you have the right to access your data at any time to either change or remove your
    information by contacting us at{' '}
    <ClickTracker category="Auth" event="Click privacy contact">
      <a href="mailto:data-privacy@blueorigin.com">data-privacy@blueorigin.com</a>
    </ClickTracker>
    . You can access our{' '}
    <ClickTracker category="Auth" event="Click view privacy policy">
      <a href="https://www.blueorigin.com/privacy-policy" target="_blank">
        privacy policy here
      </a>
    </ClickTracker>
  </React.Fragment>
);
