import { AppLoading, Auth, Error } from '@blueorigin/blue-branding-kit';
import { UserInfo } from '@blueorigin/input-validation';
import gql from 'graphql-tag';
import { History } from 'history';
import * as _ from 'lodash';
import * as React from 'react';
import { Query, QueryResult } from 'react-apollo';
import { Route, Switch } from 'react-router-dom';
import { ActivateAstronautBuyerAccount } from './activate-astronaut-buyer-account';

// tslint:disable:no-var-requires
const EarthAtNight = require('../../assets/BlueOrigin_EarthAtNight.jpg');

export const GetBuyerActivation = gql`
  query Q($activation: VerifyActivationInput!) {
    verifyActivation(activation: $activation) {
      success
      activationToken
      email
      firstName
      lastName
    }
  }
`;

interface MatchParams {
  name: string;
}

export interface RouteComponentProps<P> {
  match?: Match<P>;
  history?: History;
}

export interface Match<P> {
  params: P;
  path: string;
  url: string;
}

interface BuyerAccountProps extends RouteComponentProps<MatchParams> {
  history?: History;
  match?: any;
}

interface BuyerAccountState {
  activationId: any;
}

export class BuyerAccount extends React.Component<BuyerAccountProps, BuyerAccountState> {
  public state: BuyerAccountState = {
    activationId: null,
  };

  public componentDidMount() {
    const activationId = this.props.match?.params?.id || null;
    if (!activationId) {
      this.props.history.push('/');
    }
    this.setState({ activationId });
  }

  public render() {
    const { activationId } = this.state;
    return (
      <Query query={GetBuyerActivation} variables={{ activation: { activationId } }}>
        {this.renderComponent}
      </Query>
    );
  }

  public renderComponent = ({ loading, error, data, refetch }: QueryResult) => {
    if (loading) {
      return <AppLoading />;
    }

    if (
      error ||
      !data.verifyActivation ||
      !data.verifyActivation.success ||
      !data.verifyActivation.email ||
      !data.verifyActivation.firstName ||
      !data.verifyActivation.lastName
    ) {
      return (
        <Error data={error} tryAgain={refetch}>
          Could not load data
        </Error>
      );
    }

    const { activationId } = this.state;
    const { email: userName, firstName, lastName } = data.verifyActivation;
    const userInfo: UserInfo = {
      userName,
      firstName,
      lastName,
    };

    return (
      <Auth width="800px" image={EarthAtNight}>
        <Switch>
          <Route
            path="/"
            render={props => (
              <ActivateAstronautBuyerAccount
                {...props}
                activationId={activationId}
                userInfo={userInfo}
              />
            )}
          />
        </Switch>
      </Auth>
    );
  };
}
