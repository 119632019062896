import { OktaRoutes } from '@blueorigin/authentication-library';
import { Blue } from '@blueorigin/blue-branding-kit';
import { AnalyticsProvider } from '@blueorigin/customer-analytics/components/analytics-provider';
import { AnalyticsRouter } from '@blueorigin/customer-analytics/components/router/analytics-router';
import ApolloClient from 'apollo-boost';
import 'cross-fetch/polyfill';
import * as React from 'react';
import { ApolloProvider } from 'react-apollo';
import { Provider } from 'react-redux';
import { Authentication } from './auth/authentication';
import { appConfig } from './config';
import store from './redux/store';

const client = new ApolloClient({
  uri: appConfig.apolloUri,
});

export const App = () => (
  <AnalyticsProvider config={{ siteId: appConfig.telemetryId }}>
    <AnalyticsRouter>
      <ApolloProvider client={client}>
        <Blue>
          <Provider store={store}>
            <OktaRoutes
              authUri={window.location.origin}
              config={appConfig.okta}
              wrapper={Authentication}
            />
          </Provider>
        </Blue>
      </ApolloProvider>
    </AnalyticsRouter>
  </AnalyticsProvider>
);
