import { AppLoading } from '@blueorigin/blue-branding-kit';
import { AnalyticsContext } from '@blueorigin/customer-analytics/components/analytics-provider';
import { withOktaAuth } from '@okta/okta-react';
import { IOktaContext } from '@okta/okta-react/bundles/types/OktaContext';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { setAuthenticated } from '../redux/actions/authenticated.actions';

export interface LogoutProps extends IOktaContext {
  history?: any;
  setAuthenticated?: (authenticated: boolean) => void;
}
export interface LogoutState {
  loading?: boolean;
}

export class LogoutComponent extends React.Component<LogoutProps, LogoutState> {
  public static contextType = AnalyticsContext;

  public constructor(props: LogoutProps) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  public render() {
    return <AppLoading />;
  }

  public async componentDidMount() {
    this.context.analyticsService.event('Auth', 'Logout');
    this.props.setAuthenticated(false);
    try {
      await this.props.oktaAuth.signOut('/');
    } catch (error) {
      // Users have already signed out
      this.props.history.push('/');
    }
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setAuthenticated: (authenticated: boolean) => dispatch(setAuthenticated({ authenticated })),
  };
};

export const Logout = withOktaAuth(connect(null, mapDispatchToProps)(LogoutComponent));
