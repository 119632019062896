import {
  ErrorDialog,
  ErrorModalCloseInteraction,
  Heading,
  Segment,
  Text,
} from '@blueorigin/blue-branding-kit';
import {
  SelectOption,
  ValidationForm,
  ValidationSelect,
  ValidationSubmitButton,
  ValidationTextField,
} from '@blueorigin/form-components';
import { required } from '@blueorigin/input-validation';
import gql from 'graphql-tag';
import * as _ from 'lodash';
import React = require('react');
import { Mutation } from 'react-apollo';
import { Link } from 'react-router-dom';

export const CreateDeclineMutation = gql`
  mutation M($invitation: ConfirmInvitationInput!) {
    confirmInvitation(invitation: $invitation) {
      success
    }
  }
`;

const DECLINE_REASONS: SelectOption[] = [
  {
    label: 'Not interested in going to space',
    value: 'not_interested',
  },
  {
    label: 'Concerned about the risks',
    value: 'risks',
  },
  {
    label: 'Not enough time',
    value: 'busy',
  },
  {
    label: 'I do not know {buyer}',
    value: 'do_not_know',
  },
  {
    label: '{buyer} should not be contacting me',
    value: 'no_contact',
  },
  {
    label: 'Other',
    value: 'other',
  },
  {
    label: 'Prefer not to say',
    value: 'prefer_not_to_say',
  },
];

export enum DeclineFailureReason {
  RESPONSE_SUCCESS_FALSE,
  OTHER,
}

export interface InvitationDeclineProps {
  history?: any;
  match?: any;
  buyer: string;
}

export interface InvitationDeclineState {
  error: any;
  errorMessage: string;
}

export class InvitationDecline extends React.Component<
  InvitationDeclineProps,
  InvitationDeclineState
> {
  public formConfig = {
    reason: required,
    comment: (): any[] => [],
  };
  constructor(props: InvitationDeclineProps) {
    super(props);
    this.state = {
      error: null,
      errorMessage: '',
    };
  }

  public render() {
    return <Mutation mutation={CreateDeclineMutation}>{this.renderForm}</Mutation>;
  }

  public renderForm = (declineInvitation: any) => {
    const { buyer } = this.props;
    const { id } = this.props.match.params;
    const options = _.map(DECLINE_REASONS, o => ({
      ...o,
      label: _.replace(o.label, /\{buyer\}/gi, buyer),
    }));
    return (
      <ValidationForm
        config={this.formConfig}
        onSubmit={data => this.onSubmit({ declineInvitation, ...data })}
      >
        <Segment>
          <Link to={`/invitation/${id}`}>View &amp; accept invitation</Link>
        </Segment>
        <Segment>
          <Heading>Decline Invitation</Heading>
        </Segment>
        <Segment>
          <Text>
            <strong>We&apos;re sorry to hear that you prefer not to fly with us!</strong>
          </Text>
        </Segment>
        <Segment>
          <Text>Please let us know why you are declining.</Text>
        </Segment>
        <Segment>
          <ValidationSelect fullWidth field="reason" label="Reason" options={options} />
        </Segment>
        <Segment>
          <ValidationTextField fullWidth multiline field="comment" label="Comment" />
        </Segment>
        <Segment>
          <ValidationSubmitButton>Decline</ValidationSubmitButton>
        </Segment>
        <Segment>{this.state.error && this.renderError()}</Segment>
      </ValidationForm>
    );
  };

  public onSubmit = (data: any) => {
    const { declineInvitation, reason, comment } = data;
    const { id } = this.props.match.params;
    declineInvitation({
      variables: {
        invitation: {
          invitationId: id,
          decision: reason,
          comment,
          password: '',
        },
      },
    })
      .then((result: any) => {
        const { success } = result.data.confirmInvitation;
        if (success) {
          this.props.history.push(`/invitation/${id}/decline/success`);
        } else {
          this.declineFailed(DeclineFailureReason.RESPONSE_SUCCESS_FALSE, result);
        }
      })
      .catch((error: any) => {
        this.declineFailed(DeclineFailureReason.OTHER, error);
      });

    return true;
  };

  public declineFailed = (reason: DeclineFailureReason, error: any) => {
    if (reason === DeclineFailureReason.OTHER) {
      this.setState({
        ...this.state,
        error,
        errorMessage: 'Invitation was not successfully declined. Please check internet connection.',
      });
    } else {
      this.setState({
        ...this.state,
        error,
        errorMessage: 'Invitation was not successfully declined. Please try again.',
      });
    }
  };
  public onClose = () => {
    this.setState({ ...this.state, error: null });
  };
  public renderError = () => {
    const { errorMessage, error } = this.state;
    return (
      <ErrorDialog
        data={error}
        close={ErrorModalCloseInteraction.ACKNOWLEDGE}
        onClose={this.onClose}
      >
        {errorMessage}
      </ErrorDialog>
    );
  };
}
