import actionCreatorFactory from 'typescript-fsa';

export interface CredentialsState {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
}

export const initialCredentialsState: CredentialsState = {
  email: null,
  password: null,
  firstName: null,
  lastName: null,
};

const actionCreator = actionCreatorFactory();

export const storeCredentials = actionCreator<any>('STORE_CREDENTIALS');
export const clearCredentials = actionCreator('CLEAR_CREDENTIALS');
