import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { InvitationUtility } from './invitation-utility';

export interface InvitationProps {
  history?: any;
}

export class Invitation extends React.Component<InvitationProps> {
  public render() {
    return (
      <Switch>
        <Route path="/invitation/:id" component={InvitationUtility} />
        <Route path="/invitation">
          <Redirect to="/" />
        </Route>
      </Switch>
    );
  }
}
