import { Auth } from '@blueorigin/blue-branding-kit';
import { AnalyticsContext } from '@blueorigin/customer-analytics/components/analytics-provider';
import { BlueHeader } from '@blueorigin/public-site-components/components/blue-header/blue-header';
import * as React from 'react';
import { PendingApproval } from '../components/pending-approval';

export class ActivationProblem extends React.Component {
  public static contextType = AnalyticsContext;

  public componentDidMount() {
    this.context.analyticsService.event('Auth', 'Problem activating account');
  }

  public render() {
    return (
      <BlueHeader>
        <Auth>
          <div style={{ color: 'black' }}>
            <PendingApproval />
          </div>
        </Auth>
      </BlueHeader>
    );
  }
}
