import { OktaAuth } from '@okta/okta-auth-js';
import * as _ from 'lodash';

export const COGNITO_USER_NOT_FOUND = 'UserNotFoundException';
export const COGNITO_NOT_AUTHORIZED = 'NotAuthorizedException';

export enum LoginResultType {
  PASSWORD_CHANGE_REQUIRED,
  MFA_REQUIRED,
  SUCCESS,
  FAILURE_INCORRECT_CREDENTIALS,
  FAILURE_UNKNOWN,
}

export interface LoginResult {
  type: LoginResultType;
  token?: string;
  oktaUserInfo?: any;
}

export class LoginService {
  public static async authenticate(
    oktaAuth: OktaAuth,
    email: string,
    password: string,
  ): Promise<LoginResult> {
    try {
      const res = await oktaAuth.signInWithCredentials({
        username: email,
        password,
      });
      if (res.status === 'SUCCESS') {
        return {
          type: LoginResultType.SUCCESS,
          token: res.sessionToken,
          oktaUserInfo: res.user.profile,
        };
      } else if (res.status === 'PASSWORD_EXPIRED') {
        return { type: LoginResultType.PASSWORD_CHANGE_REQUIRED, oktaUserInfo: res.user.profile };
      } else if (res.status === 'MFA_REQUIRED') {
        return { type: LoginResultType.MFA_REQUIRED, oktaUserInfo: res.user.profile };
      } else {
        throw { type: LoginResultType.FAILURE_UNKNOWN };
      }
    } catch (error) {
      if (_.get(error, ['errorSummary']) === 'Authentication failed') {
        throw { type: LoginResultType.FAILURE_INCORRECT_CREDENTIALS };
      } else {
        throw { type: LoginResultType.FAILURE_UNKNOWN };
      }
    }
  }
}
