import { Segment } from '@blueorigin/blue-branding-kit';
import {
  ValidationCheckbox,
  ValidationForm,
  ValidationPassword,
  ValidationSubmitButton,
  ValidationTextField,
} from '@blueorigin/form-components';
import {
  all,
  customerPassword,
  passwordConfirmation,
  required,
  UserInfo,
} from '@blueorigin/input-validation';
import * as _ from 'lodash';
import * as React from 'react';
import { ExpiredPasswordChangeFailReason } from '../service/password-service';
import { RegistrationConsent } from './registration-consent';

export interface ChangePasswordProps {
  buttonText: string;
  onSubmit: (password: string) => void;
  userInfo: UserInfo;
  error: ExpiredPasswordChangeFailReason;
  errorSummary: string;
  onPasswordFormChange: () => void;
}

export interface ChangePasswordState {
  password: string;
  passwordConfirm: string;
  consent: boolean;
}

export class PasswordForm extends React.Component<ChangePasswordProps, ChangePasswordState> {
  public formConfig = {
    password: all(
      required,
      customerPassword(() => _.get(this.props, ['userInfo'], null)),
    ),
    passwordConfirm: all(
      required,
      passwordConfirmation(() => _.get(this.state, ['password'], '')),
    ),
    consent: required,
  };

  public render() {
    return (
      <ValidationForm config={this.formConfig} onChange={this.onChange} onSubmit={this.onSubmit}>
        <Segment>
          <ValidationPassword field="password" fullWidth />
        </Segment>
        <Segment>
          <ValidationTextField
            field="passwordConfirm"
            label="Re-type password"
            type="password"
            fullWidth
          />
        </Segment>
        <Segment>
          <ValidationCheckbox field="consent">
            <RegistrationConsent />
          </ValidationCheckbox>
        </Segment>
        <Segment>
          <ValidationSubmitButton>{this.props.buttonText}</ValidationSubmitButton>
        </Segment>
      </ValidationForm>
    );
  }

  public onChange = (data: any) => {
    this.setState(data);
    this.props.onPasswordFormChange();
    return true;
  };

  public onSubmit = (data: any) => {
    this.props.onSubmit(data.password);
    return true;
  };
}
