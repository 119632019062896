import { reducerWithInitialState } from 'typescript-fsa-reducers';
import * as Actions from '../actions/credentials.actions';
import { initialCredentialsState } from '../actions/credentials.actions';

export const CredentialsReducer = reducerWithInitialState(initialCredentialsState)
  .case(Actions.storeCredentials, (state, args) => ({
    ...state,
    email: args.email,
    password: args.password,
    firstName: args.firstName,
    lastName: args.lastName,
  }))
  .case(Actions.clearCredentials, state => ({
    ...state,
    email: null,
    password: null,
    firstName: null,
    lastName: null,
  }))
  .build();
