interface IAppConfig {
  payloadUri: string;
  astronautUri: string;
  pedigreeUri: string;
  okta: typeof okta;
  apolloUri: string;
  telemetryId: number;
}

const okta = {
  clientId: '0oali9qcgw2nfUPt1356',
  postLogoutRedirectUri: window.location.origin,
};

let config: IAppConfig;
const validEnvironment = typeof APPLICATION !== 'undefined';
if (validEnvironment && 'prod' === APPLICATION.ENVIRONMENT) {
  config = {
    apolloUri: 'https://acl6jl4bsi.execute-api.us-gov-west-1.amazonaws.com/prod/graphql',
    payloadUri: 'https://payloads.blueorigin.com',
    astronautUri: 'https://astronauts.blueorigin.com',
    pedigreeUri: 'https://pedigree.blueorigin.com',
    okta,
    telemetryId: 4,
  };
} else if (validEnvironment && 'gamma' === APPLICATION.ENVIRONMENT) {
  config = {
    apolloUri: 'https://p56ta1od6e.execute-api.us-gov-west-1.amazonaws.com/gamma/graphql',
    payloadUri: 'https://gamma-payloads.blueorigin.com',
    astronautUri: 'https://gamma-astronauts.blueorigin.com',
    pedigreeUri: 'https://gamma-pedigree.blueorigin.com',
    okta,
    telemetryId: 3,
  };
} else {
  config = {
    apolloUri: 'https://fxg7n71194.execute-api.us-gov-west-1.amazonaws.com/beta/graphql',
    payloadUri: 'https://beta-payloads.blueorigin.com',
    astronautUri: 'https://beta-astronauts.blueorigin.com',
    pedigreeUri: 'https://beta-pedigree.blueorigin.com',
    okta,
    telemetryId: 1,
  };
}

export const appConfig = config;
