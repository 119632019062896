import { reducerWithInitialState } from 'typescript-fsa-reducers';
import * as Actions from '../actions/authenticated.actions';
import { initialAuthenticatedState } from '../actions/authenticated.actions';

export const AuthenticatedReducer = reducerWithInitialState(initialAuthenticatedState)
  .case(Actions.setAuthenticated, (state, { authenticated }) => {
    return {
      ...state,
      authenticated,
    };
  })
  .case(Actions.clearAuthenticated, state => ({
    ...state,
    authenticated: null,
  }))
  .build();
