import {
  Auth,
  ErrorDialog,
  HalfSpace,
  Heading,
  PrimaryBlue,
  PrimaryGreen,
  QuarterSpace,
  Segment,
  White,
} from '@blueorigin/blue-branding-kit';
import { AnalyticsContext } from '@blueorigin/customer-analytics/components/analytics-provider';
import { ClickTracker } from '@blueorigin/customer-analytics/components/click-tracker';
import { Button, TextField } from '@material-ui/core';
import { withOktaAuth } from '@okta/okta-react';
import { IOktaContext } from '@okta/okta-react/bundles/types/OktaContext';
import * as React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router';
import { Dispatch } from 'redux';
import styled from 'styled-components';
import { storeCredentials } from '../redux/actions/credentials.actions';
import { LoginResult, LoginResultType, LoginService } from '../service/login-service';

const MISC_ERROR = 'Could not log in right now. Check your connection and try again';
// tslint:disable:no-var-requires
const Earth = require('../assets/public.png');
const Logo = require('../assets/logo-blue-origin.svg');

const StyledLink = styled.a`
  text-decoration: none;
  color: ${PrimaryBlue};
`;

export const StyledLogoWrap = styled.img`
  padding-top: ${QuarterSpace};
  padding-bottom: ${QuarterSpace};
`;

export interface LoginProps extends IOktaContext {
  history?: any;
  storeCredentials: (email: string, password: string, firstName: string, lastName: string) => void;
}

export interface LoginState {
  email: string;
  password: string;
  resultType: any;
  loading: boolean;
}

export class LoginComponent extends React.Component<LoginProps, LoginState> {
  public static contextType = AnalyticsContext;

  constructor(props: LoginProps) {
    super(props);
    this.state = {
      email: '',
      password: '',
      resultType: null,
      loading: false,
    };
  }

  public render() {
    const { resultType, email, password, loading } = this.state;
    const disabled = !(email && password) || loading;
    return (
      <Auth image={Earth} marginTop="150px">
        <form onSubmit={this.onSubmit}>
          <Segment>
            <StyledLogoWrap src={Logo} alt="Logo" width="175" />
          </Segment>
          <Segment>
            <div style={{ color: 'black' }}>
              <Heading>Sign in</Heading>
            </div>
          </Segment>
          <Route path="/activation-successful" render={this.activationSuccessfulNotice} />
          <Segment>
            <TextField label="Email address" onChange={this.onEmailChange} fullWidth />
          </Segment>
          <Segment>
            <TextField
              label="Password"
              type="password"
              onChange={this.onPasswordChange}
              error={resultType === LoginResultType.FAILURE_INCORRECT_CREDENTIALS}
              helperText={
                resultType === LoginResultType.FAILURE_INCORRECT_CREDENTIALS
                  ? 'Email or password is incorrect'
                  : null
              }
              fullWidth
            />
          </Segment>
          <Segment>
            <div style={{ textAlign: 'right' }}>
              <ClickTracker category="Auth" event="Forgot password">
                <StyledLink
                  href="https://okta-external.blueorigin.com/signin/forgot-password"
                  target="_blank"
                >
                  Forgot password?
                </StyledLink>
              </ClickTracker>
            </div>
          </Segment>
          <Segment>
            <ClickTracker category="Auth" event="Login button clicked">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={disabled}
                fullWidth
              >
                Sign in
              </Button>
            </ClickTracker>
          </Segment>
          {resultType === LoginResultType.FAILURE_UNKNOWN ? (
            <ErrorDialog data={{}} tryAgain={this.onSubmit}>
              {MISC_ERROR}
            </ErrorDialog>
          ) : null}
        </form>
      </Auth>
    );
  }

  public activationSuccessfulNotice = () => {
    return (
      <Segment>
        <div style={{ backgroundColor: PrimaryGreen, color: White, padding: HalfSpace }}>
          Your account has been activated. Sign in to continue.
        </div>
      </Segment>
    );
  };

  public onEmailChange = (event: any) => {
    this.setState({
      ...this.state,
      email: event.target.value,
      resultType: null,
    });
  };

  public onPasswordChange = (event: any) => {
    this.setState({
      ...this.state,
      password: event.target.value,
      resultType: null,
    });
  };

  public onSubmit = (event: any) => {
    event.preventDefault();
    this.setState({
      ...this.state,
      loading: true,
    });
    LoginService.authenticate(this.props.oktaAuth, this.state.email, this.state.password)
      .then(this.onLoginSuccess)
      .catch(this.onLoginFailure);
  };

  public onLoginSuccess = (result: LoginResult) => {
    const { firstName, lastName } = result.oktaUserInfo;
    const { email, password } = this.state;
    this.context.analyticsService.event('Auth', 'Login success');
    if (result.type === LoginResultType.PASSWORD_CHANGE_REQUIRED) {
      this.props.storeCredentials(email, password, firstName, lastName);
      this.props.history.push('/expired-password');
      return;
    }

    this.props.oktaAuth.signInWithRedirect({
      sessionToken: result.token,
      scopes: ['openid', 'email', 'profile', 'groups'],
    });
  };

  public onLoginFailure = (result: LoginResult) => {
    this.context.analyticsService.event('Auth', 'Login failure');
    this.setState({
      ...this.state,
      resultType: result.type,
      loading: false,
    });
  };
}

export const mapDispatchToProps = (dispatch: Dispatch) => ({
  storeCredentials: (email: string, password: string, firstName: string, lastName: string) =>
    dispatch(storeCredentials({ email, password, firstName, lastName })),
});

export const Login = withOktaAuth(connect(null, mapDispatchToProps)(LoginComponent));
