import { AppLoading, Auth, Error } from '@blueorigin/blue-branding-kit';
import gql from 'graphql-tag';
import * as _ from 'lodash';
import * as React from 'react';
import { Query, QueryResult } from 'react-apollo';
import { Route, Switch } from 'react-router-dom';
import { CreateAstronautAccount } from './create-astronaut-account';
import { DeclineMessage } from './decline-message';
import { InvitationAccept } from './invitation-accept';
import { InvitationDecline } from './invitation-decline';

// tslint:disable:no-var-requires
const EarthAtNight = require('../../assets/BlueOrigin_EarthAtNight.jpg');

export const GetInvitation = gql`
  query Q($invitation: VerifyInvitationInput!) {
    verifyInvitation(invitation: $invitation) {
      success
      buyerDetails {
        displayName
        firstName
        lastName
      }
    }
  }
`;

interface InvitationUtilityProps {
  history?: any;
  match?: any;
}

interface InvitationUtilityState {
  invitationId: any;
}

export class InvitationUtility extends React.Component<
  InvitationUtilityProps,
  InvitationUtilityState
> {
  public state: InvitationUtilityState = {
    invitationId: null,
  };

  public componentDidMount() {
    const invitationId = _.get(this.props, ['match', 'params', 'id'], null);
    if (!invitationId) {
      this.props.history.push('/');
    }
    this.setState({ invitationId });
  }

  public render() {
    const { invitationId } = this.state;
    return (
      <Query query={GetInvitation} variables={{ invitation: { invitationId } }}>
        {this.renderComponent}
      </Query>
    );
  }

  public renderComponent = ({ loading, error, data, refetch }: QueryResult) => {
    if (loading) {
      return <AppLoading />;
    }

    if (error || !data.verifyInvitation || !data.verifyInvitation.success) {
      return (
        <Error data={error} tryAgain={refetch}>
          Could not load data
        </Error>
      );
    }

    const buyerDetails = data.verifyInvitation.buyerDetails;
    const { invitationId } = this.state;

    return (
      <Auth width="800px" image={EarthAtNight}>
        <Switch>
          <Route
            path="/invitation/:id/create-account"
            render={props => <CreateAstronautAccount {...props} invitationId={invitationId} />}
          />
          <Route path="/invitation/:id/decline/success" component={DeclineMessage} />
          <Route
            path="/invitation/:id/decline"
            render={props => <InvitationDecline {...props} buyer={buyerDetails.displayName} />}
          />
          <Route
            path="/invitation/:id"
            render={props => <InvitationAccept {...props} buyer={buyerDetails.displayName} />}
          />
        </Switch>
      </Auth>
    );
  };
}
