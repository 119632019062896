import {
  Block,
  ErrorDialog,
  Heading,
  Segment,
  SegmentSpacing,
  Text,
} from '@blueorigin/blue-branding-kit';
import {
  ValidationForm,
  ValidationPassword,
  ValidationSubmitButton,
  ValidationTextField,
} from '@blueorigin/form-components';
import {
  all,
  customerPassword,
  passwordConfirmation,
  required,
} from '@blueorigin/input-validation';
import { withOktaAuth } from '@okta/okta-react';
import { IOktaContext } from '@okta/okta-react/bundles/types/OktaContext';
import gql from 'graphql-tag';
import * as _ from 'lodash';
import React = require('react');
import { Mutation } from 'react-apollo';
import styled from 'styled-components';
import { LoginResult, LoginResultType, LoginService } from '../../service/login-service';

export const FormWrap = styled.div`
  max-width: 350px;
`;

export const ConfirmInvitationMutation = gql`
  mutation M($invitation: ConfirmInvitationInput!) {
    confirmInvitation(invitation: $invitation) {
      success
      email
    }
  }
`;

interface CreateAstronautAccountComponentProps extends IOktaContext {
  invitationId: string;
}

export class CreateAstronautAccountComponent extends React.Component<CreateAstronautAccountComponentProps> {
  public state = {
    isSigningIn: false,
    isError: false,
  };
  public formConfig = {
    password: all(
      required,
      customerPassword(() => _.get(this.props, ['userInfo'], null)),
    ),
    passwordConfirm: all(
      required,
      passwordConfirmation(() => {
        return _.get(this.state, ['password'], '');
      }),
    ),
  };

  public render() {
    return <Mutation mutation={ConfirmInvitationMutation}>{this.renderForm}</Mutation>;
  }

  public renderForm = (confirmInvitation: any, { data, loading, error, refetch }: any) => {
    if (error || this.state.isError) {
      return (
        <ErrorDialog data={error} tryAgain={refetch}>
          Could not create account
        </ErrorDialog>
      );
    }

    return (
      <ValidationForm
        config={this.formConfig}
        onChange={this.onChange}
        onSubmit={formData => this.onSubmit({ confirmInvitation, ...formData })}
        disabled={!!loading || this.state.isSigningIn}
      >
        <Block>
          <Segment>
            <Heading>Create Account</Heading>
          </Segment>
          <Segment>
            <Text>
              <strong>Congratulations!</strong> You have taken your first step to a life-changing
              adventure. Next, create an account, to access your dashboard and astronaut profile.
            </Text>
          </Segment>
        </Block>
        <Block border={false}>
          <FormWrap>
            <Segment>
              <ValidationPassword field="password" fullWidth />
            </Segment>
            <Segment>
              <ValidationTextField
                field="passwordConfirm"
                label="Re-type your password"
                type="password"
                fullWidth
              />
            </Segment>

            <Segment spacing={SegmentSpacing.MORE}>
              <ValidationSubmitButton size="large">Start my journey</ValidationSubmitButton>
            </Segment>
          </FormWrap>
        </Block>
      </ValidationForm>
    );
  };
  public onChange = (data: any) => {
    this.setState(data);
    return true;
  };

  public onSubmit = (data: any) => {
    const { confirmInvitation, passwordConfirm } = data;
    this.setState({ isSigningIn: true }, () => {
      confirmInvitation({
        variables: {
          invitation: {
            invitationId: this.props.invitationId,
            decision: 'Accepted',
            password: passwordConfirm,
          },
        },
      }).then((result: any) => {
        const success = _.get(result, ['data', 'confirmInvitation', 'success'], null);
        const email = _.get(result, ['data', 'confirmInvitation', 'email'], null);
        if (success && email) {
          this.autoLogin(email, passwordConfirm);
        } else {
          this.setState({ isError: true, isSigningIn: false });
        }
      });
    });

    return true;
  };

  public autoLogin = (email: string, password: string) => {
    LoginService.authenticate(this.props.oktaAuth, email, password)
      .then(this.onLoginSuccess)
      .catch(this.onLoginFailure);
  };

  public onLoginSuccess = (result: LoginResult) => {
    this.props.oktaAuth.signInWithRedirect({
      sessionToken: result.token,
      scopes: ['openid', 'email', 'profile', 'groups'],
    });
  };

  public onLoginFailure = (result: LoginResult) => {
    this.setState({ isError: true, isSigningIn: false });
  };
}

export const CreateAstronautAccount = withOktaAuth(CreateAstronautAccountComponent);
