import { OktaAuth } from '@okta/okta-auth-js';
import * as _ from 'lodash';

export const MIN_PW_LENGTH = 10;

const NUM_VALUE = 1;
const LOWER_VALUE = 2;
const UPPER_VALUE = 3;
const SYMBOL_VALUE = 4;
const RECOMMENDED_SCORE = NUM_VALUE * 2 + LOWER_VALUE * 18 + UPPER_VALUE * 2;

export interface RequirementResult {
  hasLength: boolean;
  hasNumber: boolean;
  hasUpper: boolean;
  hasLower: boolean;
  hasSymbol: boolean;
  numberMatches: number;
  upperMatches: number;
  lowerMatches: number;
  symbolMatches: number;
}

export enum ExpiredPasswordChangeFailReason {
  NOT_REQUIRED,
  OTHER,
}

export enum ExpiredPasswordChangeResultType {
  SUCCESS,
  FAILURE,
}

export interface ExpiredPasswordChangeResult {
  type: ExpiredPasswordChangeResultType;
  token: string;
}

export class PasswordService {
  public static resumeOrBegin(oktaAuth: OktaAuth, email: string, password: string) {
    if (oktaAuth.tx.exists()) {
      return oktaAuth.tx.resume();
    } else {
      return oktaAuth.signInWithCredentials({
        username: email,
        password,
      });
    }
  }

  public static changeExpiredPassword(
    oktaAuth: OktaAuth,
    email: string,
    oldPassword: string,
    newPassword: string,
  ): Promise<ExpiredPasswordChangeResult> {
    return PasswordService.resumeOrBegin(oktaAuth, email, oldPassword)
      .then((res: any) => {
        if (res.status === 'PASSWORD_EXPIRED') {
          return res
            .changePassword({
              oldPassword,
              newPassword,
            })
            .then((changeResult: any) => {
              if (changeResult.status === 'SUCCESS') {
                return {
                  type: ExpiredPasswordChangeResultType.SUCCESS,
                  token: changeResult.sessionToken,
                };
              } else {
                throw { type: ExpiredPasswordChangeResultType.FAILURE, errorSummary: null };
              }
            })
            .catch((err: any) => {
              // where error is handled for current or recently used password
              const errorSummary: string = _.get(err, ['errorCauses', 0, 'errorSummary'], null);
              throw {
                type: ExpiredPasswordChangeResultType.FAILURE,
                errorSummary,
              };
            });
        } else {
          throw { type: ExpiredPasswordChangeResultType.FAILURE, errorSummary: null };
        }
      })
      .catch((err: any) => {
        const errorSummary: string = err.errorSummary || null;
        throw {
          type: ExpiredPasswordChangeResultType.FAILURE,
          errorSummary,
        };
      });
  }

  public static meetsRequirements(password: string): boolean {
    const req = PasswordService.checkRequirements(password);
    return (
      req.hasLength &&
      req.hasUpper &&
      req.hasLower &&
      req.hasNumber &&
      req.hasSymbol &&
      req.hasLength
    );
  }

  public static checkRequirements(password: string): RequirementResult {
    const hasLength = password.length >= 12;
    const numberMatches = PasswordService.countMatches(/[0-9]/g, password);
    const lowerMatches = PasswordService.countMatches(/[a-z]/g, password);
    const upperMatches = PasswordService.countMatches(/[A-Z]/g, password);
    const symbolMatches = PasswordService.countMatches(/((?![a-zA-Z0-9]).)/g, password);
    const hasNumber = numberMatches >= 1;
    const hasLower = lowerMatches >= 1;
    const hasUpper = upperMatches >= 1;
    const hasSymbol = symbolMatches >= 1;
    return {
      hasLength,
      hasNumber,
      hasLower,
      hasUpper,
      hasSymbol,
      numberMatches,
      lowerMatches,
      upperMatches,
      symbolMatches,
    };
  }

  public static scorePassword(password: string, outOf: number = 100): number {
    const req = PasswordService.checkRequirements(password);
    let score = 0;
    if (req.hasNumber) {
      score += req.numberMatches * NUM_VALUE;
    }
    if (req.hasLower) {
      score += req.lowerMatches * LOWER_VALUE;
    }
    if (req.hasUpper) {
      score += req.upperMatches * UPPER_VALUE;
    }
    if (req.hasSymbol) {
      score += req.symbolMatches * SYMBOL_VALUE;
    }
    return Math.floor(Math.min(score / RECOMMENDED_SCORE, 1) * outOf);
  }

  private static countMatches(regex: RegExp, str: string): number {
    return ((str || '').match(regex) || []).length;
  }
}
