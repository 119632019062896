import { Block, ErrorDialog, Heading, Segment, Text, theme } from '@blueorigin/blue-branding-kit';
import {
  ValidationCheckbox,
  ValidationForm,
  ValidationPassword,
  ValidationSubmitButton,
  ValidationTextField,
} from '@blueorigin/form-components';
import {
  all,
  customerPassword,
  passwordConfirmation,
  required,
  UserInfo,
} from '@blueorigin/input-validation';
import { withOktaAuth } from '@okta/okta-react';
import { IOktaContext } from '@okta/okta-react/bundles/types/OktaContext';
import gql from 'graphql-tag';
import * as _ from 'lodash';
import React = require('react');
import { Mutation } from 'react-apollo';
import styled from 'styled-components';
import { RegistrationConsent } from '../../components/registration-consent';
import { LoginResult, LoginService } from '../../service/login-service';

export const FormWrap = styled.div`
  max-width: 350px;
`;

export const CheckboxFormWrap = styled.div`
  padding-top: ${theme('Dimensions.Space')};
`;

export const ButtonFormWrap = styled.div`
  padding-top: ${theme('Dimensions.Space')};
`;

export const ActivateAccountMutation = gql`
  mutation M($activation: ActivateAccountInput!) {
    activateAccount(activation: $activation) {
      success
    }
  }
`;

interface ActivateAstronautBuyerAccountComponentProps extends IOktaContext {
  activationId: string;
  userInfo: UserInfo;
}

export class ActivateAstronautBuyerAccountComponent extends React.Component<ActivateAstronautBuyerAccountComponentProps> {
  public state = {
    isSigningIn: false,
    isError: false,
    password: '',
  };

  public formConfig = {
    password: all(
      required,
      customerPassword(() => this.props.userInfo),
    ),
    passwordConfirm: all(
      required,
      passwordConfirmation(() => this.state.password),
    ),
    consent: required,
  };

  public render() {
    return <Mutation mutation={ActivateAccountMutation}>{this.renderForm}</Mutation>;
  }

  public renderForm = (activateAccount: any, { data, loading, error, refetch }: any) => {
    return (
      <ValidationForm
        config={this.formConfig}
        onChange={this.onChange}
        onSubmit={formData => this.onSubmit({ activateAccount, ...formData })}
        disabled={!!loading || this.state.isSigningIn}
        saving={this.state.isSigningIn}
      >
        <Block>
          <Segment>
            <Heading>Create Account</Heading>
          </Segment>
          <Segment>
            <Text>
              <strong>Congratulations!</strong> You have taken your first step to a life-changing
              adventure. Next, create an account to access your dashboard.
            </Text>
          </Segment>
        </Block>
        <Block border={false}>
          <FormWrap>
            <Segment>
              <ValidationPassword field="password" fullWidth />
            </Segment>
            <Segment>
              <ValidationTextField
                field="passwordConfirm"
                label="Re-type your password"
                type="password"
                fullWidth
              />
            </Segment>
          </FormWrap>
          <CheckboxFormWrap>
            <ValidationCheckbox field="consent">
              <RegistrationConsent />
            </ValidationCheckbox>
          </CheckboxFormWrap>
          <ButtonFormWrap>
            <ValidationSubmitButton size="large">Start my journey</ValidationSubmitButton>
          </ButtonFormWrap>

          {(this.state.isError || error) && (
            <ErrorDialog data={error}>
              Password could not be set. Please try again with a different password.
            </ErrorDialog>
          )}
        </Block>
      </ValidationForm>
    );
  };
  public onChange = (data: any) => {
    this.setState(data);
    return true;
  };

  public onSubmit = (data: any) => {
    const { activateAccount, passwordConfirm } = data;
    this.setState({ isSigningIn: true }, () => {
      activateAccount({
        variables: {
          activation: {
            activationId: this.props.activationId,
            password: passwordConfirm,
          },
        },
      }).then((result: any) => {
        const success = result.data?.activateAccount?.success || null;
        const { userName } = this.props.userInfo;
        if (success && userName) {
          this.autoLogin(userName, passwordConfirm);
        } else {
          this.setState({ isError: true, isSigningIn: false });
        }
      });
    });
    return true;
  };

  public autoLogin = (email: string, password: string) => {
    LoginService.authenticate(this.props.oktaAuth, email, password)
      .then(this.onLoginSuccess)
      .catch(this.onLoginFailure);
  };

  public onLoginSuccess = (result: LoginResult) => {
    this.props.oktaAuth.signInWithRedirect({
      sessionToken: result.token,
      scopes: ['openid', 'email', 'profile', 'groups'],
    });
  };

  public onLoginFailure = (result: LoginResult) => {
    this.setState({ isError: true, isSigningIn: false });
  };
}

export const ActivateAstronautBuyerAccount = withOktaAuth(ActivateAstronautBuyerAccountComponent);
