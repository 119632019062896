import { combineReducers, compose, createStore } from 'redux';
import { AuthenticatedState } from './actions/authenticated.actions';
import { CredentialsState } from './actions/credentials.actions';
import { AuthenticatedReducer } from './reducers/authenticated.reducer';
import { CredentialsReducer } from './reducers/credentials.reducer';

export interface AppState {
  credentials: CredentialsState;
  authenticated: AuthenticatedState;
}

const composeEnhancers =
  (typeof window === 'object' && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export default createStore(
  combineReducers({
    credentials: CredentialsReducer,
    authenticated: AuthenticatedReducer,
  }),
  composeEnhancers(),
);
