import { Block, Heading, Segment, Text } from '@blueorigin/blue-branding-kit';
import { Button } from '@material-ui/core';
import * as _ from 'lodash';
import React = require('react');

export class DeclineMessage extends React.Component<{}> {
  public render() {
    return (
      <React.Fragment>
        <Segment>
          <Heading>Invitation Declined</Heading>
        </Segment>
        <Segment>
          <Text>
            <strong>Thank you for responding!</strong>
          </Text>
        </Segment>
        <Segment>
          <Text>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi vestibulum risus id
            porttitor rutrum. Etiam dictum eu dui eget interdum. Vivamus in sagittis urna.
          </Text>
        </Segment>
        <Segment>
          <Button variant="contained" color="primary" href="http://www.blueorigin.com/">
            Go to blueorigin.com
          </Button>
        </Segment>
      </React.Fragment>
    );
  }
}
