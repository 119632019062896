import { AppLoading, Error } from '@blueorigin/blue-branding-kit';
import { withOktaAuth } from '@okta/okta-react';
import { IOktaContext } from '@okta/okta-react/bundles/types/OktaContext';
import * as React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';
import { Dispatch } from 'redux';
import { setAuthenticated } from '../redux/actions/authenticated.actions';
import { AppState } from '../redux/store';
import { ActivationProblem } from './activation-problem';
import { BuyerAccount } from './buyer-account/buyer-account';
import { Connect } from './connect';
import { ExpiredPassword } from './expired-password';
import { Invitation } from './invitation/invitation';
import { Login } from './login';
import { Logout } from './logout';

export interface AuthenticationProps extends IOktaContext {
  authenticated: boolean;
  setAuthenticated: (authenticated: boolean) => void;
}

export class AuthenticationComponent extends React.Component<AuthenticationProps> {
  public componentDidMount() {
    if (this.props.authenticated === null) {
      this.checkAuth();
    }
  }

  public render() {
    const { authenticated } = this.props;
    if (authenticated === true) {
      return this.renderUser();
    } else if (authenticated === false) {
      return this.renderGuest();
    } else if (authenticated === null) {
      return this.renderLoading();
    } else {
      return (
        <Error reload data={{ authenticated }}>
          Try reloading the page
        </Error>
      );
    }
  }

  private checkAuth() {
    this.props.oktaAuth.isAuthenticated().then((authenticated: boolean) => {
      this.props.setAuthenticated(authenticated);
    });
  }

  private renderUser() {
    return (
      <Switch>
        <Route path="/signout" component={Logout} />
        <Route path="/activation-problem" component={ActivationProblem} />
        <Route component={Connect} />
      </Switch>
    );
  }

  private renderGuest() {
    return (
      <Switch>
        <Route path="/expired-password" component={ExpiredPassword} />
        <Route path="/invitation" component={Invitation} />
        <Route path="/activate-account/:id" component={BuyerAccount} />
        <Route path="/activation-problem" component={ActivationProblem} />
        <Route path="/signout" component={Logout} />
        <Route component={Login} />
      </Switch>
    );
  }

  private renderLoading() {
    return <AppLoading />;
  }
}

const mapStateToProps = (state: AppState, props: AuthenticationProps) => {
  return {
    ...props,
    authenticated: state.authenticated.authenticated,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setAuthenticated: (authenticated: boolean) => dispatch(setAuthenticated({ authenticated })),
  };
};

export const Authentication = withOktaAuth(
  connect(mapStateToProps, mapDispatchToProps)(AuthenticationComponent),
);
