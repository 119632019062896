import { Heading, Segment, Text } from '@blueorigin/blue-branding-kit';
import { ClickTracker } from '@blueorigin/customer-analytics/components/click-tracker';
import { Button } from '@material-ui/core';
import * as React from 'react';
import { EMAIL, HOMEPAGE_URL } from '../constants';

export class PendingApproval extends React.Component {
  public render() {
    return (
      <React.Fragment>
        <Segment>
          <Heading>Pending Review</Heading>
        </Segment>
        <Segment>
          <Text>
            Thank you for creating a Blue Origin account. To remain compliant with U.S. regulations,
            we have placed your account under manual review. You will receive an email notification
            when we reach a decision on how to proceed. If you have any questions, please contact us
            at
            <ClickTracker category="Auth" event="Click kyc contact email">
              <a href={`mailto:${EMAIL}`}>{EMAIL}</a>
            </ClickTracker>
          </Text>
        </Segment>
        <Segment>
          <ClickTracker category="Auth" event="Back to blueorigin.com">
            <Button variant="contained" color="primary" onClick={this.onClick} fullWidth>
              Back to blueorigin.com
            </Button>
          </ClickTracker>
        </Segment>
      </React.Fragment>
    );
  }

  public onClick = () => {
    window.location.assign(HOMEPAGE_URL);
  };
}
