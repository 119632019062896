import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { App } from './App';
import './index.css';

declare const module: any;

ReactDOM.render(<App />, document.getElementById('content'));

if (module.hot) {
  module.hot.accept();
}
