import actionCreatorFactory from 'typescript-fsa';

export interface AuthenticatedState {
  authenticated: boolean;
}

export const initialAuthenticatedState: AuthenticatedState = {
  authenticated: null,
};

const actionCreator = actionCreatorFactory();

export const setAuthenticated = actionCreator<any>('SET_AUTHENTICATED');
export const clearAuthenticated = actionCreator('CLEAR_AUTHENTICATED');
